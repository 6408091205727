<template>
  <div class="stream">
    <div class="stream__container">
      <div class="stream__nav">
        <div class="stream__nav-item">
          <div class="stream__buttons">
            <div v-if="!isStartStream" class="stream__button" @click="createNewSession">
              Подключить аватар стрим
            </div>
            <div v-else class="stream__button" @click="createNewSession">Обновить</div>

            <!-- <div class="stream__button" @click="startAndDisplaySession">Start</div> -->
          </div>
          <!-- <div class="stream__dialog">
            <textarea placeholder="Просто повторяет текст" type="text" v-model="text" />
            <div class="stream__button" @click="repeatHandler">Talk</div>
          </div> -->
          <video
            width="400"
            height="400"
            playsinline
            id="mediaElement"
            class="videoEle show"
            autoplay
            muted
          ></video>
          <!-- <canvas id="canvasElement" class="videoEle hide"></canvas> -->
        </div>
        <div class="stream__nav-item">
          <!-- <div v-if="!assistantId" class="stream__buttons">
            <input placeholder="feed id" type="text" v-model="feedId" />
            <div class="stream__button" @click="createAssistant">Create Assistant</div>
          </div> -->
          <template v-if="assistantId">
            <div class="stream__buttons">
              <div class="stream__button" @click="deleteAssistant">Сменить ЖК</div>
            </div>

            <div class="stream__dialog">
              <label for="">ЖК {{ nameComplex }}</label>
              <textarea placeholder="Question" type="text" v-model="question" />
              <div class="stream__button" @click="askAssistant">Задать вопрос</div>

              <!-- <label for="">Answer</label>
              <textarea readonly placeholder="Answer" type="text" v-model="answer" /> -->
            </div>
          </template>
          <template v-else>
            <div class="stream__complexes-title">Выберите ЖК для создания ассистента.</div>
            <div class="stream__complexes">
              <div
                @click="createAssistant(item)"
                v-for="item in complexes"
                :key="item.id"
                class="stream__complex"
              >
                {{ item.name }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <FetchSpinnerModal v-if="loadingProcess" />
  </div>
</template>

<script>
import assisApi from "~/api/assis";

import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";

export default {
  name: "StreamDemo",
  metaInfo: {
    title: "Ai ассистент",
  },
  data() {
    return {
      avatar: "Kayla-incasualsuit-20220818",
      voice_id: "94acfc4407274bfc9bb8c3803808d842",
      apiKey: "ZDRmMDVjODEyNjMxNDg4Njk5YmIzMWJiNGMxYzQyNDYtMTcxMzk4NjMyOA==",
      SERVER_URL: "https://api.heygen.com",
      sessionInfo: null,
      peerConnection: null,
      text: "",
      isStartStream: false,
      feedId: null,
      loadingProcess: false,
      assistantId: null,
      nameComplex: "",
      question: "",
      answer: "",
      threadId: null,
      complexes: [
        // {
        //   id: 1,
        //   name: "Эмоушен",
        // },
        // {
        //   id: 2,
        //   name: "Мирополис",
        // },
        // {
        //   id: 3,
        //   name: "Наметкин",
        // },
        // {
        //   id: 4,
        //   name: "Инди тауэрс",
        // },
        // {
        //   id: 5,
        //   name: "Апсайд",
        // },
        // {
        //   id: 6,
        //   name: "МелиСад",
        // },
        // {
        //   id: 7,
        //   name: "Инди тауэрс*",
        // },
        {
          id: 8,
          name: "Пятницкое 58",
        },
      ],
    };
  },
  components: {
    FetchSpinnerModal,
  },
  mounted() {
    const mediaElement = document.querySelector("#mediaElement");
    // let mediaCanPlay = false;
    mediaElement.onloadedmetadata = () => {
      // mediaCanPlay = true;
      mediaElement.muted = false;
      mediaElement.play();

      if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      ) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          // для Safari
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          // для Firefox
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          // для IE/Edge
          document.msExitFullscreen();
        }
      }
    };

    if (localStorage.getItem("threadId")) {
      this.feedId = localStorage.getItem("feedId");
    }

    if (localStorage.getItem("threadId")) {
      this.nameComplex = localStorage.getItem("nameComplex");
    }

    if (localStorage.getItem("threadId")) {
      this.assistantId = localStorage.getItem("assistantId");
    }

    if (localStorage.getItem("threadId")) {
      this.threadId = localStorage.getItem("threadId");
    }

    // this.createAssistant();
  },
  beforeDestroy() {
    if (this.sessionInfo) {
      const resp = this.stopSession(this.sessionInfo.session_id);
    }
  },
  methods: {
    async createNewSession() {
      const avatar = this.avatar;
      const voice = this.voice_id;

      const mediaElement = document.querySelector("#mediaElement");

      // call the new interface to get the server's offer SDP and ICE server to create a new RTCPeerConnection
      this.sessionInfo = await this.newSession("medium", avatar, voice);
      const { sdp: serverSdp, ice_servers2: iceServers } = this.sessionInfo;

      // Create a new RTCPeerConnection
      this.peerConnection = new RTCPeerConnection({ iceServers: iceServers });

      // When audio and video streams are received, display them in the video element
      this.peerConnection.ontrack = (event) => {
        console.log("Received the track");
        if (event.track.kind === "audio" || event.track.kind === "video") {
          mediaElement.srcObject = event.streams[0];
        }
      };

      // When receiving a message, display it in the status element
      this.peerConnection.ondatachannel = (event) => {
        const dataChannel = event.channel;
        dataChannel.onmessage = this.onMessage;
      };

      // Set server's SDP as remote description
      const remoteDescription = new RTCSessionDescription(serverSdp);
      await this.peerConnection.setRemoteDescription(remoteDescription);

      this.startAndDisplaySession();
    },

    onMessage(event) {
      const message = event.data;
      console.log("Received message:", message);
    },

    async newSession(quality, avatar_name, voice_id) {
      this.loadingProcess = true;
      const response = await fetch(`${this.SERVER_URL}/v1/streaming.new`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": this.apiKey,
        },
        body: JSON.stringify({
          quality,
          avatar_name,
          voice: {
            voice_id: voice_id,
          },
        }),
      });
      if (response.status === 500) {
        console.error("Server error");
        this.loadingProcess = false;
        throw new Error("Server error");
      } else {
        const data = await response.json();
        console.log(data.data);
        this.loadingProcess = false;
        return data.data;
      }
    },

    async handleICE(session_id, candidate) {
      const response = await fetch(`${this.SERVER_URL}/v1/streaming.ice`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": this.apiKey,
        },
        body: JSON.stringify({ session_id, candidate }),
      });
      if (response.status === 500) {
        console.error("Server error");
        throw new Error("Server error");
      } else {
        const data = await response.json();
        return data;
      }
    },

    async startAndDisplaySession() {
      this.loadingProcess = true;
      const sessionInfo = this.sessionInfo;
      if (!sessionInfo) {
        alert("create connection first");
        return;
      }
      // Create and set local SDP description
      const localDescription = await this.peerConnection.createAnswer();
      await this.peerConnection.setLocalDescription(localDescription);

      // When ICE candidate is available, send to the server
      this.peerConnection.onicecandidate = ({ candidate }) => {
        console.log("Received ICE candidate:", candidate);
        if (candidate) {
          this.handleICE(sessionInfo.session_id, candidate.toJSON());
        }
      };

      // When ICE connection state changes, display the new state
      this.peerConnection.oniceconnectionstatechange = (event) => {
        console.log(`ICE connection state changed to: ${this.peerConnection.iceConnectionState}`);
      };

      // Start session
      await this.startSession(sessionInfo.session_id, localDescription);
      this.isStartStream = true;
      this.loadingProcess = false;
    },

    async startSession(session_id, sdp) {
      const response = await fetch(`${this.SERVER_URL}/v1/streaming.start`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": this.apiKey,
        },
        body: JSON.stringify({ session_id, sdp }),
      });
      if (response.status === 500) {
        console.error("Server error, startSession");

        throw new Error("Server error");
      } else {
        const data = await response.json();
        return data.data;
      }
    },

    async repeatHandler() {
      if (!this.sessionInfo) {
        alert("Please create a connection first");

        return;
      }
      console.log("Sending task... please wait");
      const text = this.text;
      if (text.trim() === "") {
        alert("Please enter a task");
        return;
      }

      const resp = await this.repeat(this.sessionInfo.session_id, text);

      console.log("Task sent successfully");
    },

    async repeat(session_id, text) {
      this.loadingProcess = true;

      const response = await fetch(`${this.SERVER_URL}/v1/streaming.task`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": this.apiKey,
        },
        body: JSON.stringify({ session_id, text }),
      });
      if (response.status === 500) {
        console.error("Server error repeat");
        this.loadingProcess = false;

        throw new Error("Server error");
      } else {
        const data = await response.json();
        this.loadingProcess = false;
        return data.data;
      }
    },

    async stopSession(session_id) {
      const response = await fetch(`${this.SERVER_URL}/v1/streaming.stop`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": this.apiKey,
        },
        body: JSON.stringify({ session_id }),
      });
      if (response.status === 500) {
        console.error("Server error stopSession");
        throw new Error("Server error");
      } else {
        const data = await response.json();
        return data.data;
      }
    },
    createAssistant(data) {
      this.feedId = data.id;
      this.nameComplex = data.name;

      if (!this.feedId) {
        alert("Need feed id");
        return;
      }
      this.loadingProcess = true;

      assisApi
        .createAssistant({ feed_id: this.feedId, fileSearch: true })
        .then((res) => {
          if (res.success) {
            localStorage.setItem("assistantId", res.data.assistantId);
            localStorage.setItem("feedId", this.feedId);
            localStorage.setItem("nameComplex", this.nameComplex);
            this.assistantId = res.data.assistantId;
          }
          this.loadingProcess = false;
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
          this.loadingProcess = false;
        });
    },
    askAssistant() {
      if (!this.assistantId) {
        alert("Need create assistant");
        return;
      }
      if (this.question.length < 1) {
        alert("Need question");
        return;
      }
      this.loadingProcess = true;
      const data = {
        assistant_id: this.assistantId,
        message: this.question,
        feed_id: this.feedId,
        fileSearch: true,
      };

      if (localStorage.getItem("threadId")) {
        data.thread_id = localStorage.getItem("threadId");
      }
      assisApi
        .messageAssistant(data)
        .then((res) => {
          if (res.success) {
            localStorage.setItem("threadId", res.data.threadId);
            this.answer = res.data.response;
            this.question = "";

            if (this.isStartStream) {
              this.repeat(this.sessionInfo.session_id, this.answer);
            }
          }
          this.loadingProcess = false;
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
          this.loadingProcess = false;
        });
    },
    deleteAssistant() {
      this.assistantId = null;
      this.feedId = null;
      this.nameComplex = "";
      this.threadId = null;
      localStorage.removeItem("assistantId");
      localStorage.removeItem("threadId");
      localStorage.removeItem("feedId");
      localStorage.removeItem("nameComplex");
    },
  },
};
</script>

<style lang="scss" scoped>
.stream {
  padding: 40px 70px;

  @media (max-width: 768px) {
    padding: 30px 20px;
  }

  &__nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  &__nav-item {
    max-width: 400px;

    @media (max-width: 768px) {
      padding-bottom: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      video {
        width: 300px;
        height: 300px;
      }
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }
  &__button {
    padding: 10px 15px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    background: #3c2a1e;
    width: fit-content;
    margin-right: 20px;
    border-radius: 10px;
  }
  &__dialog {
    padding: 30px 0;

    label {
      display: block;
      font-size: 16px;
      font-weight: 700;
      padding: 10px 0;
    }

    textarea {
      width: 400px;
      height: 100px;
      padding: 10px;
      margin-bottom: 10px;
      font-size: 16px;
      -webkit-text-size-adjust: 100%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__complexes {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__complex {
    padding: 10px 15px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    background: #3c2a1e;
    width: fit-content;
    margin: 0 10px 10px 0;
    border-radius: 10px;
  }

  &__complexes-title {
    font-size: 18px;
    text-align: center;
    padding: 15px 0;
  }
}
</style>
